@import 'src/styles/vars';

.backDrop {
  padding: 0 !important;
}

.header:global(.modal-header) {
  padding: 8px 20px;
}

.title:global(.modal-title) {
  font-size: 14px;
}

.dialog:global(.modal-dialog) {
  //position: relative;
  width: 100VW;
  height: 100VH;
  max-width: 100VW;
  max-height: 100VH;
  margin: 0;

  .body {}

  :global(.modal-content) {
    width: 100%;
  }

  &:global(.modal-dialog-scrollable) {
    :global(.modal-content) {
      max-height: 100%;
    }
  }

  :global(#viewerContainer) {
    top: 0;
    bottom: 0;
    margin-bottom: 47px;

    :global(.pdfViewer) {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden !important;
      padding: 10px 0 100px;

      :global(.page) {
        border: 0;
        margin: 12px;
        border-image: unset;
        box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, .25);
      }
    }
  }

  :global(#pageNumber) {
    display: none !important;
    opacity: 0 !important;
    z-index: -1000 !important;
  }
}

.dialog :global(.mobile__pdf__container) > footer {
  height: 3rem;
  background-image: unset;
  box-shadow: 0 0 10px rgba(0, 0, 0, .25);

  :global(.toolbarButton) {
    background-image: unset;
    border: 2px solid transparent;

    &:hover {
      border-color: rgba(0, 0, 0, .25);
    }

    &:active {
      border-color: rgba(0, 0, 0, .5);
    }

    &:global(.pageUp) {
      background-image: url('../../../assets/img/left.png');
    }
    &:global(.pageDown) {
      background-image: url('../../../assets/img/right.png');
    }
    &:global(.zoomIn) {
      background-image: url('../../../assets/img/plus.png');
    }
    &:global(.zoomOut) {
      background-image: url('../../../assets/img/minus.png');
    }
  }
}

.viewer {
  margin-bottom: -10px;
  width: 100%;
  height: 100%;
}

viewer-pdf-toolbar-new {
  display: none !important;
}
