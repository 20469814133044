@import 'src/styles/vars';

.sidebar {
  position: relative;
  composes: flex-column from global;
  min-height: 100%;
  width: 100%;
  min-width: $sidebar-width;
  padding: 18px 0 18px;
  border-left: 1px solid $border-color-light;
}

.handle {
  position: relative;
  display: inline-block;
  max-height: 36px;
  margin-bottom: 30px;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px;

  &:hover {
    background-color: rgba($secondary-color, .05);
  }
}

.arrowIcon {
  width: 30px;
  height: 100%;
  //padding-right: 16px;
  fill: $main;
  transform: rotate(180deg);
  padding-right: unset;
}

.section {
  position: relative;
  //display: flex;
  //display: -webkit-flex;
  //flex-direction: column;
  //-webkit-box-orient: column;
  width: 100%;
  margin-bottom: 20px;
  :global(.ant-btn-text) {
    border-radius: 6px;
    border: 1px solid $main;
    color: $main;
  }
}

.heading {
  position: relative;
  composes: flex-row from global;
  min-height: 32px;
  background-color: rgba($main, .05);
  padding: 3px 19px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
}

.content {
  position: relative;
  padding: 25px 18px 20px;
}

.search {
  :global(.ant-input-search-button) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
  }

  :global(.ant-input) {
    max-height: unset;
    height: 36px;
  }

  &::placeholder {
    background-color: rgba($secondary-color, .05);
  }
  &:empty {
    background-color: rgba($secondary-color, .05);
  }
}

.range {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > span {
    padding: 0 27px;
  }
}

.rangeInput:global(.ant-input) {
  max-height: 26px;
  max-width: 82px;
}

.slider:global(.ant-slider) {
  margin-top: 36px;
}

.checkboxes.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > label {
    margin: 0 0 6px;
  }
}

.checkbox {
}

