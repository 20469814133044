@import 'src/styles/vars';

.badge {
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 6px;
}

.badge-secondary {
  color: $border-color-dark;
  background-color: rgba(#4f4f4f, .25) !important
}

//ant-table-tbody
//ant-table-row
.ant-table-tbody .ant-table-row:nth-child(2n) {
  background-color: rgba($green-yellow, .03) !important;
}

.ant-upload-list-item {
  min-height: 25px;
  height: auto;
}

.ant-upload-list-item-info {
  padding: 4px;
}

.ant-btn-sm.ant-btn-icon-only,
.ant-btn-sm.ant-upload-list-item-card-actions-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  border-color: #D9D9DE !important;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border-color: $main !important;
}
.ant-pagination-item {
  border-color: $main !important;
  a {
    color: $main !important;
  }
  &-active a {
    color: #fff !important;
    background-color: $blue !important;
  }
}

.ant-table {
  color: #000 !important;
}
.ant-table-thead > tr > th {
  background: #D9D9DE !important;
  color: #000 !important;
}
.ant-table-tbody .ant-table-row:nth-child(2n) {
  background: #F5F5F5 !important;
}
.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
  border-color: $main !important;
}
.ant-tabs {
  color: $main !important;
}
