@import 'src/styles/vars';

.container {
  position: relative;
  display: block;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
}
.line {
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 20px;
  div {
    background: transparent;
  }
  :global(.ant-slider) {
    padding: 0;
  }
  :global(.ant-slider-rail) {
    background: #C2D1D9;
  }
  :global(.ant-slider-track) {
    background: #009587;
  }
}
.containerCarousel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.left, .right {
  width: 30px;
  height: 30px;
  cursor: pointer;
  svg {
    width: 30px;
    height: 30px;
  }
  bottom: 40%;
  transform: translateY(-50%);
}
.left {
  position: absolute;
  left: 0;
}
.right {
  position: absolute;
  right: 0;
}
.slider {
  display: block;
  width: 100%;
  padding: 0 35px;
}



.sliderView {
  display: flex;
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
}
.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  :global(.anticon svg) {
    width: 20px;
    height: 20px;
  }
  span {
    display: block;
  }
}
