@import 'src/styles/vars';

.audio {
  background: rgba(196, 196, 196, 0.16);
  border-radius: 12px;
  width: 100%;
  min-height: 250px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  audio {
    width: 100%;
  }
  .audioInfo {
    display: flex;
    .img {
      width: 180px;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      margin-top: 20px;
      span {
        font-size: 15px;
      }
      strong {
        font-size: 17px;
        margin-top: 10px;
      }
    }
  }
}
.imgContent {
  maw-width: 100%;
  height: auto;
  object-fit: cover;
  overflow: hidden;
}