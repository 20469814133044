@import 'src/styles/vars';

.mainContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.page {
  position: relative;
  composes: flex-column from global;
  //max-width: 1066px;
  flex: 1 1 auto;
  width: 100%;
  max-width: 100%;
  :global(.ant-back-top) {
    right: 20px;
  }
}