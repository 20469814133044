@import 'src/styles/vars';

.body:global(.modal-body) {
  padding: 45px 30px 15px;
  color: $text-color;
}

.footer:global(.modal-footer) {
  padding: 12px 30px;
}

.header {
  line-height: 26px;
  font-size: 24px;
  padding: 0 0 30px;
  color: inherit;
}

.message {
  line-height: 20px;
  font-size: 16px;
}
