@import "src/styles/vars";

.header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border-color-light;
  padding: 10px 20px;
  transition: 0.4s linear all;
}
.leftBar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .headerMain__toggle {
    display: none;
    @media (max-width: 992px) {
      display: block;
    }
  }
}
.navBar {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 120px;
  opacity: 0;
  height: 0;
  transform: translateY(-100%);
  transition: 0.5s linear all;
  &:global(.active) {
    display: flex;
    transform: translateY(0);
    opacity: 1;
  }
}
.linkParent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 25px;
  line-height: 64px;
  font-size: 32px;
  font-weight: 600;
  color: $main;
  text-transform: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  > :global(.anticon) {
    pointer-events: none;
    color: $blue;
  }
}
.link {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 25px;
  margin-right: 0;
  line-height: 64px;
  font-size: 24px;
  font-weight: 600;
  color: $blue;
  text-transform: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: $dark-blue;

    & > .linkChildren {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }

    & > span {
      border-bottom: 3px solid #fff;

    }

    &:global(.active) > span {
      border-bottom-color: $main;
    }
  }

  & > span {
    border-bottom: 3px solid transparent;
    display: flex;
    align-items: center;
  }

  &:global(.active) > span {
    border-bottom: 3px solid #fff;
  }
}
.linkSettings {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 25px;
  margin-right: 0;
  line-height: 25px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  text-transform: none;
  cursor: pointer;
  z-index: 1000;
  padding: 10px 0 10px 0;
  &:hover {
    text-decoration: none;
    color: $blue;
  }
}
.activeMenu {
  background-color: $green-yellow;
  color: #000;
  .pdf {
    span { color: #fff; }
  }
}
.linkParentActive {
  color: #000;
}
.linkChildren {
  width: 350px;
  display: block;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);
  transition: 0.3s ease all;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: $green;
  padding: 10px 0 10px 5px;
  z-index: 1000;
}
.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 80px;
}

.logoIcon {
  height: 100%;
  width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.userInfo {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.profile {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  border-radius: 30px;
  cursor: pointer;
  color: $secondary-color;

  &:hover {
    background-color: $avatar-background;
  }
}

.name {
  line-height: 28px;
  font-size: 24px;
  font-weight: 500;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border: 2px solid $blue;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    object-fit: cover;
  }
  & > svg {
    margin-top: 10px;
  }
}

.logout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  border-radius: 50%;
  cursor: pointer;

  .logoutIcon {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 32px;
    color: $blue;
  }

  &:hover {
    background-color: $avatar-background;
  }
}
.setting {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 15px;
  .settingIcon {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 32px;
    color: $blue;
  }
  .settingsList {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    transform: translateX(60px);
    padding: 5px;
    flex-direction: column;
    background-color: #FAFAFA;
    border-radius: 5px;
    width: 400px;
    z-index: 5;
  }
  .settingsListOpen {
    display: flex;
  }

  &:hover {
    background-color: $avatar-background;
  }
}

.pdf,
.search,
.filters {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  margin-right: 20px;
  cursor: pointer;
}

.pdf {

  & > span {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    color: $main;
    max-width: 100px;
    line-height: 16px;
    font-size: 14px;
    margin-left: 10px;
  }
}

.search {
  cursor: unset;
}

@media (min-width: 783px) {
  .userInfo {
    width: auto;
  }
}
@media (max-width: 783px) {
  .linkChildren {
    left: 0;
  }
  .userInfo{
    flex-wrap: wrap;
  }
  .pdf {
    padding-top: 15px;
    padding-bottom: 15px;
    order: 5;
    width: 100%;
    justify-content: flex-end;
    margin-right: 0;
  }
  .header{
    flex-wrap: nowrap;
  }
}
@media (max-width: 500px) {

  .logo {
    width: 80px;
  }
  .header {
    align-items: flex-start;
  }
  .linkParent{
    font-size: 26px;
    line-height: 30px;
  }
  .navBar {
    padding-left: 14px;
    &:global(.active) {
      display: flex;
    }
  }

}
@media (max-width: 400px) {
  .header{
    padding: 10px;
  }
  .settingsList {
    width: 320px !important;
  }
  .logo{
    width: 50px;
  }
  .pdf, .search, .filters, .profile {
    margin-right: 5px;
  }
  .logout {
    width: 50px;
  }
}
