@import 'src/styles/vars';

.cell {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn:global(.ant-btn.ant-btn-sm) {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  //width: 28px;
  margin-right: 5px;
  overflow: hidden;

  & > img {
    width: 200%;
  }

  &:last-child {
    margin-right: 0;
  }
}
