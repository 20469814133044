@import 'src/styles/vars';
.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  color: $main;
}

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 45px;
}

.title {
  //position: relative;
  //composes: flex-row from global;
  //align-items: center;
  line-height: 45px;
  font-size: 35px;
  font-weight: 400;
  color: $main;
  margin-right: 20px;
}

.add:global(.ant-btn) {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.icon {
  height: auto;
  width: auto;
}

.presentation {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 28px;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 45px;
  color: $main;
}

.show {
  margin: 0 20px;
}

.table {
  position: relative;
  composes: flex-row from global;

  & .leftColumn {
    padding: 0 50px 50px 0;
  }
}

.nameCell:global(.ant-table-cell) {
  position: relative;
}

.leftColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 18px 25px 50px 25px;
  transition: margin-right 300ms ease-in-out;
  //padding: 18px 0 0 28px;

  &.isSidebarCollapsed {
    margin-right: -$sidebar-width;
  }
}

.rightColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 $sidebar-width;
  min-height: 100%;
  padding: 0;
  transition: transform 300ms ease-in-out;

  &.isCollapsed {
    transform: translateX(100%);
  }
}

.handle {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px;
  max-height: 36px;

  &:hover {
    background-color: rgba($secondary-color, .05);
  }
}

.arrowIcon {
  width: 45px;
  height: 100%;
  padding-right: 16px;
  fill: $main;
}