@import '~bootstrap/scss/bootstrap';
@import "fonts";
//@import '~antd/dist/antd.css';
@import '~react-quill/dist/quill.snow.css';

@import './vars';
@import './shared';

@import './mixins/index';
@import './controls/index';

$font: Golos, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
* {
  font-family: Golos, Futura, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

body {
  font-family: $font;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
//tabs
.ant-tabs {
  color: $blue !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000 !important;
  font-family: $font;
}
.ant-tabs-ink-bar {
  background: $green-yellow !important;
}
.ant-btn-primary {
  color: #fff !important;
  text-decoration: none;
}
.ant-table-sticky-scroll {
  position: fixed;
}