@import 'src/styles/vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  padding: 50px 5px 50px 20px;
  overflow-x: hidden;
  :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    font-size: 16px;
  }
}

.leftColumn,
.contentColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100%;

  &:last-child {
    padding-right: 0;
  }
}

$left-column-width: 75px;

.leftColumn {

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100px;
  max-width: 100px;
}

.contentColumn {
  max-width: calc(100% - #{$left-column-width});
  transition: padding 300ms ease-in-out;
  overflow: hidden;
  &.contentColumnCollapsed {
    padding-right: $sidebar-width;
  }
}

.rightColumn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex: 1 0 $sidebar-width;
  min-height: 100%;
  transition: right 300ms ease-in-out;

  &.rightColumnClosed {
    right: -$sidebar-width;
  }
}

.contentColumn {
  flex: 1 1 50%;
  overflow: hidden;
  & :global(.ant-tabs) {
    margin-bottom: 40px;
    width: 100%;
  }
  :global(.ant-collapse) {
    background: #fff;
    border: none;
  }
  :global(.ant-collapse-content) {
    > & :global(.ant-collapse-content-box) {
      padding: 5px;
    }
  }
  :global(.ant-collapse) {
    & > :global(.ant-collapse-item) {
      border-bottom: none;
      margin-bottom: 15px;
    }

  }
  :global(.ant-collapse-header) {
    border: 1px solid #C2D1D9;
    border-radius: 10px;
  }
  :global(.ant-collapse) {
    & > :global(.ant-collapse-item:last-child) {
      & > :global(.ant-collapse-header) {
        border: 1px solid #C2D1D9;
        border-radius: 10px !important;
      }
    }

  }
  :global(.ant-collapse-content) {
    border-top: none;
  }

  & :global(.ant-tabs-tab.ant-tabs-tab-active) {
    border-bottom-color: $green;
    color: $blue;

    & :global(.ant-tabs-tab-btn) {
      color: $main;
    }
  }

  & :global(.ant-tabs-ink-bar.ant-tabs-ink-bar-animated) {
    background-color: $blue;
  }

  & :global(.ant-tabs-top) {
    & :global(.ant-tabs-nav::before) {
      border-color: transparent;
    }
  }
}

.item {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  composes: flex-column from global;
}

.multiFormat {
  margin-bottom: 0;
}

.title {
  line-height: 22px;
  font-size: 18px;
  font-weight: 600;
  color: $main;
  margin-bottom: 20px;
  min-width: 110px;
}

.text {
  line-height: 22px;
  font-size: 18px;
  color: $main;
}

.video {
  max-width: 100%;
}

.row {
  position: relative;
  composes: flex-row from global;
  margin-bottom: 26px;
  width: 100%;
}

.rowItem {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 50%;
  //flex-wrap: wrap;

  & .title {
    margin-bottom: 4px;
  }

  & .text {
    line-height: 32px;
    font-size: 26px;
    color: $blue;
    overflow-wrap: break-word;
  }
}

.image {
  min-width: 100%;
  max-width: 100%;
}

.name {
  line-height: 64px;
  font-size: 54px;
  font-weight: 600;
  color: $main;
}

.owner {
  line-height: 32px;
  font-size: 26px;
  color: $main;
}

.budget {
  font-weight: 600;
}

.socials {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}


.documentsList {
  composes: flex-column from global;
}

.document {
  margin-bottom: 8px;
  cursor: pointer;
}

.pdfImg {
  height: 35px;
  width: 35px;
  margin-right: 28px;
}

.documentImg {
  height: 22px;
  width: 22px;
  margin-left: 28px;
}
.videoContent {
  color: #84BD00;
}

.blogSphere {
  color: #FF2E56;
}

.multiFormat {
  color: #FFC700;
}
.nav {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  h3 {
    margin-bottom: 0;
    margin-right: 15px;
  }
}
.tab {
  display: flex;
  flex-direction: column;
  .tabInfo {
    display: flex;
    justify-content: space-between;
    h3 {
      color: $main;
    }
  }
  .add {
    margin-bottom: 30px;
  }
  :global(.ant-collapse) > :global(.ant-collapse-item) > :global(.ant-collapse-header),
  :global(.ant-collapse) > :global(.ant-collapse-item:last-child) > :global(.ant-collapse-header){
    border: none;
    color: $main;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  :global(.ant-collapse-content-box) {
    color: rgba(0, 0, 0, 0.5);
  }
  .tabCard {
    display: flex;
    width: 100%;
    .tabCardLeft {
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tabCardRight {
      width: 60%;
      padding-left: 30px;
      strong {
        padding-left: 15px;
      }
    }
  }
}
@media (max-width: 990px) {
  .container {
    flex-wrap: wrap;
  }
  .leftColumn {
    width: 100%;
  }
  .contentColumn {
    width: 100%;
    padding-right: 0;
    flex: 1 1 100%;
    &:global(.ant-tabs-nav-list) {
      flex-wrap: wrap;
    }
  }
  .leftSection {
    order: 1;
  }
  .tab {
    .tabCard {
      flex-wrap: wrap;
      .tabCardLeft {
        width: 100%;
      }
      .tabCardRight {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}
@media (max-width: 500px) {
  .container {
    padding: 15px 20px;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .leftColumn {
    width: 100%;
    max-width: 100%;
    height: 50px;
    min-height: 50px;
  }
  .text {
    overflow-wrap: break-word;
  }
  .name{
    font-size: 28px;
    line-height: 35px;
    overflow-wrap: break-word;
  }
  .owner {
    font-size: 24px;
    line-height: 28px;
  }
  .image {
    max-width: 100%;
    display: block;
  }
  .contentColumn {
    max-width: 500px;
    overflow: hidden;
    position: relative;
    & :global(.ant-tabs-tab + .ant-tabs-tab) {
      margin: 0 0 0 10px;
    }
  }
}
@media (max-width: 320px) {
  .contentColumn {
    & :global(.ant-tabs-nav-list) {
      flex-direction: column;
    }
    & :global(.ant-tabs-tab + .ant-tabs-tab) {
      margin: 0;
    }
  }
  .container {
    padding: 15px;
  }
}