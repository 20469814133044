@import 'src/styles/vars';
.cell {
  padding: 0;
  border-radius: 4px;
  :global(.ant-badge) {
    color: $main;
    padding: 8px;
  }
}

