@import 'src/styles/vars';

.backDrop:global(.modal-backdrop) {
  z-index: 1050;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
}

.modalHeader:global(.modal-body) {
  height: auto;
}

.body:global(.modal-body) {
  padding: 30px 30px 15px;
  color: $text-color;
}

.modalHeader:global(.modal-body),
.footer:global(.modal-footer) {
  padding: 12px 30px;
}
