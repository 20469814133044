@import 'src/styles/vars';
.modalVote {
  :global(.modal-content) {
    overflow-y: scroll !important;
  }

}
.body:global(.modal-body) {
  padding: 45px 30px 15px;
  color: $text-color;
  overflow: auto;
}

.footer:global(.modal-footer) {
  padding: 12px 30px;
}

.header {
  line-height: 26px;
  font-size: 24px;
  padding: 0 0 30px;
  color: inherit;
}

.formItem {
  position: relative;

  & :global(.ant-input-number) {
    width: unset;
    max-width: 240px;
  }
}

.label {
  line-height: 18px;
  font-size: 16px;
  color: $text-color;
  width: 100%;
}

.filesList {
  padding: 4px;
  margin: 10px 0 0 0;
}

.uploadedFiles {
  padding-bottom: 10px;
}

.document {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  cursor: pointer;

  & :global(.ant-btn) {
    margin-left: 10px;
  }
}

.pdfImg {
  height: 35px;
  width: 35px;
  margin-right: 28px;
}

.documentImg {
  height: 22px;
  width: 22px;
  margin-left: 28px;
}
