@import 'src/styles/vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  color: $main;
}

.header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.title {
  //position: relative;
  //composes: flex-row from global;
  //align-items: center;
  line-height: 45px;
  font-size: 35px;
  font-weight: 400;
  margin-right: 20px;
  cursor: pointer;
  color: $blue;
  transition: 0.3s ease all;
  &:hover {
    color: $dark-blue;
  }
}

.add:global(.ant-btn) {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: $blue;
  transition: 0.3s ease all;
  &:hover {
    color: $dark-blue;
  }
}

.icon {
  height: auto;
  width: auto;
}

.leftColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 18px 25px 50px 25px;
  transition: margin-right 300ms ease-in-out;
  //padding: 18px 0 0 28px;

  &.isSidebarCollapsed {
    margin-right: -$sidebar-width;
  }
}

.rightColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 $sidebar-width;
  min-height: 100%;
  padding: 0;
  transition: transform 300ms ease-in-out;

  &.isCollapsed {
    transform: translateX(100%);
  }
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.item {
  position: relative;
  margin: 0 25px 25px 0;
  width: 168px;
}

.preview {
  width: 168px;
  height: 70px;
  border: $accent-color 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
  :global(.anticon svg) {
    width: 30px;
    height: 30px;
  }
  :global(.anticon-delete svg) {
    width: 18px;
    height: 18px;
    color: tomato;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}

.watchBtn:global(.ant-btn) {
  margin-top: 5px;
  width: 100%;
  height: unset;
  line-height: 14px;
  font-size: 12px;
}

.description {
  display: inline-block;
  text-align: center;
  width: 100%;
  //height: auto;
  height: 42px;
  max-width: 100%;
  line-height: 14px;
  font-size: 12px;
  word-break: break-all;
  overflow: hidden;
  white-space: pre-line;
  //text-overflow: ellipsis;
  margin-top: 5px;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px;
}

.masActions {
  width: 100%;
  display: flex;
  align-items: flex-end;
  > button {
    margin-left: 15px;
  }
}

.masActionsSelect{
  display: flex;
  flex-direction: column;
  > div {
    min-width: 200px;
  }
}

.leftColumn{
  overflow-x: auto;
}
.info {
  border-bottom: 1px solid rgba($blue, 0.5);
  padding-bottom: 15px;
  margin-bottom: 20px;
  span.title {
    font-size: 25px;
    line-height: 30px;
  }
  p{
    font-size: 18px;
  }
  :global(textarea) {
    width: 50%;
  }
}
@media (max-width: 1150px) {
  .leftColumn{
    overflow-x: scroll;
  }
}

@media (min-width: 770px) {
  .masActions {
    width: auto;
    margin-left: 30px;
  }
  .masActionsSelect{
    > div {
      min-width: 300px;
    }
  }
}
@media (max-width: 500px) {
  .list {
    justify-content: space-between;
  }
  .item {
    margin: 0;
  }
  .info {
    :global(textarea) {
      width: 100%;
    }
  }
}
@media (max-width: 385px) {
  .list {
    justify-content: center;
  }
}
