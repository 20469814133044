@import "src/styles/vars";
.pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pdfChild{
  margin-bottom: 10px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, .05);
}

.nav{
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
}

.btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}
.close {
  &:global(.ant-btn) {
    background-color: $coral !important;
    border: 1px solid $coral !important;
  }
}
