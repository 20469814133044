@import "src/styles/vars";

.mainTemplate {
  min-height: 100vh;
  display: flex;
  width: 100%;

  &__wrapper {
    width: 100%;
    overflow: hidden;
    &:global(.isTablet:not(.isMobile)) {
        padding-left: 80px;
    }
  }

  &__content {
    overflow-x: hidden;
    width: 100%;
  }
}
