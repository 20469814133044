@import 'src/styles/vars';

.backDrop:global(.modal-backdrop) {
  z-index: 1050;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
}

.modalHeader:global(.modal-body) {
  height: auto;
}

.body {
  :global(.modal-body) {
    padding: 30px 30px 15px;
    color: $text-color;
  }
  :global(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
    margin-left: 0
  }
}

.modalHeader:global(.modal-body),
.footer:global(.modal-footer) {
  padding: 12px 30px;
}

.label {
  width: 100%;
}
.checkbox {
  margin-bottom: 20px;
  font-weight: bold;
}
.list {
  display: flex !important;
  flex-direction: column;
  label {
    width: 100%;
  }
}
.img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  margin-left: 5px;
  object-fit: contain;
}
