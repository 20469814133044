@import 'src/styles/vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  padding: 50px;
}

.leftColumn,
.contentColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100%;
  padding-right: 100px;

  &:last-child {
    padding-right: 0;
  }
}

.leftColumn {
  width: 165px;
  max-width: 165px;
}

.contentColumn {
  flex: 1 1 50%;

  & :global(.ant-tabs) {
    margin-bottom: 40px;
  }

  & :global(.ant-tabs-tab.ant-tabs-tab-active) {
    border-bottom-color: $secondary-color;

    & :global(.ant-tabs-tab-btn) {
      color: $secondary-color;
    }
  }

  & :global(.ant-tabs-ink-bar.ant-tabs-ink-bar-animated) {
    background-color: $secondary-color;
  }

  & :global(.ant-tabs-top) {
    & :global(.ant-tabs-nav::before) {
      border-color: transparent;
    }
  }
  hr {
    width: 100%;
    height: 1px;
    box-sizing: border-box;
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
}

.useravatar{
  display: block;
  width: 200px;
}
.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    display: block;
    max-width: 100%;
    transition: 0.3s ease-in-out all;
    margin-bottom: 15px;
  }
}
.avatar:hover{
  img {
    transform: scale(1.05);
  }
}
.addImage__input {
  display: none;
}
.cell {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  div {
    display: flex;
    align-items: center;
    cursor: pointer;
    button {
      margin-right: 8px;
    }
  }
}

.btn:global(.ant-btn.ant-btn-sm) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  margin-bottom: 5px;

  &:last-child {
    margin-right: 0;
  }
}
.list {
  display: flex;
  flex-direction: column;
  h4{
    margin-top: 20px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #333333;
    margin-bottom: 15px;
    li{
      display: flex;
      span{
        font-weight: bold;
        min-width: 120px;
      }
    }
  }
}
.userAction{
  overflow-x: auto;
}

@media (max-width: 990px) {
  .container {
    flex-wrap: wrap;
  }
  .leftColumn {
    width: 100%;
    max-width: 100%;
  }
  .contentColumn {
    width: 100%;
    padding-right: 0;
    flex: 1 1 100%
  }
  .leftSection {
    order: 1;
  }
}
@media (max-width: 500px) {
  .container {
    padding: 15px 20px;
    overflow: hidden;
  }
  .text {
    overflow-wrap: break-word;
  }
  .name{
    font-size: 28px;
    line-height: 35px;
    overflow-wrap: break-word;
  }
  .owner {
    font-size: 24px;
    line-height: 28px;
  }
  .image {
    max-width: 100%;
    display: block;
  }
  .contentColumn {
    max-width: 500px;
    overflow: hidden;
    position: relative;
    & :global(.ant-tabs-tab + .ant-tabs-tab) {
      margin: 0 0 0 10px;
    }
  }
}
@media (max-width: 320px) {
  .contentColumn {
    & :global(.ant-tabs-nav-list) {
      flex-direction: column;
    }
    & :global(.ant-tabs-tab + .ant-tabs-tab) {
      margin: 0;
    }
  }
  .container {
    padding: 15px;
  }
}