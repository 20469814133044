@import 'src/styles/vars';

.cell {
  position: relative;
  display: flex;
  //justify-content: center;
  align-items: center;
}

.image {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

