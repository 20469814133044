@import 'src/styles/vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  padding: 50px;
  overflow: hidden;
}

.leftColumn,
.contentColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 100px;
  width: 100%;
  overflow: hidden;
  &:last-child {
    padding-right: 0;
  }
}

.leftColumn {
  width: 165px;
  max-width: 165px;
}

.contentColumn {
  flex: 1 1 100%;

  & :global(.ant-tabs) {
    margin-bottom: 40px;
  }

  & :global(.ant-tabs-tab.ant-tabs-tab-active) {
    border-bottom-color: $secondary-color;

    & :global(.ant-tabs-tab-btn) {
      color: $secondary-color;
    }
  }

  & :global(.ant-tabs-ink-bar.ant-tabs-ink-bar-animated) {
    background-color: $secondary-color;
  }

  & :global(.ant-tabs-top) {
    & :global(.ant-tabs-nav::before) {
      border-color: transparent;
    }
  }
}

.item {
  position: relative;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 40px;
  composes: flex-column from global;
}

.multiFormat {
  margin-bottom: 0;
}

.title {
  line-height: 22px;
  font-size: 18px;
  font-weight: 400;
  color: $main;
  margin-bottom: 20px;
  min-width: 110px;
}

.specialOpinionTitle {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > Button {
    margin-left: 10px;
  }
}

.text {
  line-height: 22px;
  font-size: 18px;
  color: $main;
  width: 100%;
  //word-break: break-all;
}

.video {
  max-width: 100%;
}

.row {
  position: relative;
  composes: flex-row from global;
  margin-bottom: 26px;
  width: 100%;
}

.rowItem {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 50%;
  //flex-wrap: wrap;

  & .title {
    margin-bottom: 4px;
  }

  & .text {
    line-height: 32px;
    font-size: 26px;
    color: $main;
  }
}

.image {
  min-width: 100%;
  max-width: 100%;
}

.name {
  line-height: 64px;
  font-size: 54px;
  font-weight: 600;
  color: $blue;
}

.editable {
  cursor: pointer;
}

.lineBreak {
  //display: inline-block;
  word-break: normal;
  white-space: pre-line;
}

.owner {
  line-height: 32px;
  font-size: 26px;
  color: $main;
}

.budget {
  font-weight: 600;
}

.socials {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.documentsList {
  composes: flex-column from global;
}

.document {
  margin-bottom: 8px;
  cursor: pointer;
}

.pdfImg {
  height: 35px;
  width: 35px;
  margin-right: 28px;
}

.documentImg {
  height: 22px;
  width: 22px;
  margin-left: 28px;
}

.videoContent {
  color: $main;
}

.blogSphere {
  color: $main;
}

.multiFormat {
  color: $main;
}

.textArea:global(.ant-input) {
  max-width: 450px;
}

.commentActions {
  & :global(.ant-btn) {
    margin-right: 10px;
  }
}

@media (max-width: 990px) {
  .container {
    flex-wrap: wrap;
  }
  .leftColumn {
    width: 100%;
    max-width: 100%;
  }
  .contentColumn {
    width: 100%;
    padding-right: 0;
    flex: 1 1 100%
  }
  .leftSection {
    order: 1;
  }
}
@media (max-width: 500px) {
  .container {
    padding: 15px 20px;
    overflow: hidden;
  }
  .text {
    overflow-wrap: break-word;
  }
  .name{
    font-size: 28px;
    line-height: 35px;
    overflow-wrap: break-word;
  }
  .owner {
    font-size: 24px;
    line-height: 28px;
  }
  .image {
    max-width: 100%;
    display: block;
  }
  .contentColumn {
    max-width: 500px;
    overflow: hidden;
    position: relative;
    & :global(.ant-tabs-tab + .ant-tabs-tab) {
      margin: 0 0 0 10px;
    }
  }
}
@media (max-width: 320px) {
  .contentColumn {
    & :global(.ant-tabs-nav-list) {
      flex-direction: column;
    }
    & :global(.ant-tabs-tab + .ant-tabs-tab) {
      margin: 0;
    }
  }
  .container {
    padding: 15px;
  }
}

.reports {
  display: flex;
  flex-direction: column;
}
.report:global(.ant-btn) {
  max-width: 200px;
  margin-bottom: 5px;
}
