@import 'src/styles/vars';

.body:global(.modal-body) {
  padding: 45px 30px 15px;
  color: $text-color;
}

.footer:global(.modal-footer) {
  padding: 12px 30px;
}

.header {
  line-height: 26px;
  font-size: 24px;
  padding: 0 0 30px;
  color: inherit;
}

.message {
  line-height: 20px;
  font-size: 16px;
  video {
    max-width: 900px !important;
    max-height: 500px;
  }
}
.video {
  max-width: 900px !important;
  min-height: 500px;
}

.videoIframe {
  min-height: 500px;
}
@media (max-width: 900px) {
  .videoIframe {
    min-height: 300px;
  }
  .video {
    margin: 0 !important;
    width: 100% !important;
    height: 100%;
    max-width: 100%;
    min-height: 100%;
  }
}
@media (max-width: 350px) {
  .body:global(.modal-body) {
    padding: 10px;
  }
}