@import "src/styles/vars";
.lineBreak {
  //display: inline-block;
  word-break: normal;
  white-space: pre-line;
}
.btn {
  margin-top: 10px;
  color: $gray;
  cursor: pointer;
  transition: 0.3s ease all;
  &:hover {
    color: $blue;
  }
}