@import 'src/styles/vars';

.cell {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.switch:global(.ant-switch) {
  margin-right: 10px;
}
