@import 'src/styles/vars';

.cell {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn:global(.ant-btn.ant-btn-sm) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}
