@import 'src/styles/vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  color: $main;
}
.header {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 45px;
  &__filter {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .searchContainer {
    position: relative;
    padding: 26px 0 0;
    .search {
      width: 370px;
      @media (max-width: 500px) {
        width: 200px;
      }
      :global(.ant-input-search-button) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 36px;
      }
      :global(.ant-input) {
        max-height: unset;
        height: 36px;
      }
      &::placeholder {
        background-color: rgba($secondary-color, .05);
      }
      &:empty {
        background-color: rgba($secondary-color, .05);
      }
    }
    :global(.ant-input-search-button) {
      border-radius: 0 !important;
      &:hover {
        color: $dark-blue;
        border-color: $blue !important;
      }
    }
    .searchBox {
      display: flex;
      .openSidebar {
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $blue !important;
        font-size: 18px;
        transition: 0.3s ease all;
        border-color: #F5F5F5 !important;
        border-radius: 0 5px 5px 0 !important;
        :global(svg) {
          height: 30px;
        }
        &:hover {
          color: $dark-blue;
          border-color: $blue !important;
        }
      }
    }
  }
  .searchCount {
    font-size: 12px;
    text-align: right;
    color: $gray;
    margin-top: 5px;
  }
}

.title {
  line-height: 45px;
  font-size: 35px;
  font-weight: 400;
  color: $blue;
  margin-right: 20px;
}

.add:global(.ant-btn) {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.icon {
  height: auto;
  width: auto;
}

.presentation {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 28px;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 45px;
  color: $main;
}

.show {
  margin: 0 20px;
}

.table {
  position: relative;
  composes: flex-row from global;

  & .leftColumn {
    padding: 0 50px 50px 0;
  }
}

.nameCell:global(.ant-table-cell) {
  position: relative;
}

.leftColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 18px 25px 50px 25px;
  transition: margin-right 300ms ease-in-out;
  //padding: 18px 0 0 28px;

  &.isSidebarCollapsed {
    margin-right: -$sidebar-width;
  }
}

.rightColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 $sidebar-width;
  min-height: 100%;
  padding: 0;
  transition: transform 300ms ease-in-out;

  &.isCollapsed {
    transform: translateX(100%);
  }
}

.handle {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px;
  max-height: 36px;

  &:hover {
    background-color: rgba($secondary-color, .05);
  }
}

.arrowIcon {
  width: 45px;
  height: 100%;
  padding-right: 16px;
  fill: $main;
}

.masActions {
  width: 100%;
  display: flex;
  align-items: flex-end;
  > button {
    margin-left: 15px;
  }
}
.masActionsSelect{
  display: flex;
  flex-direction: column;
  > div {
    min-width: 200px;
  }
}
@media (max-width: 1150px) {
  .leftColumn{
    overflow-x: scroll;
  }
}

@media (min-width: 770px) {
  .masActions {
    width: auto;
    margin-left: 30px;
  }
  .masActionsSelect{
    > div {
      min-width: 300px;
    }
  }

}
.leftColumn{
  overflow-x: auto;
}
