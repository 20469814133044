@import "src/styles/vars";
.container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 30px;
}
.header {
  display: flex;
  flex-direction: column;
}
.searchBox {
  display: flex;
  gap: 30px;
  .openSidebar {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $blue !important;
    font-size: 18px;
    transition: 0.3s ease all;
    border-color: #F5F5F5 !important;
    border-radius: 0 5px 5px 0 !important;
    :global(svg) {
      height: 30px;
    }
    &:hover {
      color: $dark-blue;
      border-color: $blue !important;
    }
  }
}
.searchCount {
  font-size: 12px;
  //text-align: right;
  color: $gray;
  margin-top: 5px;
}