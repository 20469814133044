@import 'src/styles/vars';
.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  color: $main;
}

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 45px;
}

.title {
  //position: relative;
  //composes: flex-row from global;
  //align-items: center;
  line-height: 45px;
  font-size: 35px;
  font-weight: 400;
  color: $main;
  margin-right: 20px;
}

.add:global(.ant-btn) {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.icon {
  height: auto;
  width: auto;
}


.show {
  margin: 0 20px;
}

.leftColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 18px 25px 50px 25px;
  transition: margin-right 300ms ease-in-out;
  overflow-x: auto;
  &.isSidebarCollapsed {
    margin-right: -$sidebar-width;
  }
}
.rightColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 $sidebar-width;
  min-height: 100%;
  padding: 0;
  transition: transform 300ms ease-in-out;

  &.isCollapsed {
    transform: translateX(100%);
  }
}

.handle {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px;
  max-height: 36px;
  &:hover {
    background-color: rgba($secondary-color, .05);
  }
}

.arrowIcon {
  width: 45px;
  height: 100%;
  padding-right: 16px;
  fill: $main;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 10px;
}
.group{
  display: flex;
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }
}
.content_img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  img {
    margin-bottom: 5px;
    width: 50px;
    height: 50px;
    object-fit: contain;
    cursor: pointer;
  }
  & :global(.ant-upload-list-item-name) {
    display: none !important;
  }
}

.formGroup {
  min-width: 350px;
}
.control {
  button:first-child{
    margin-bottom: 5px;
  }
}
.error {
  color: #ff4d4f;
  font-weight: bold;
}
.bar {
  margin-bottom: 15px;
}
@media (max-width: 990px) {
  .item {
    display: flex;
    flex-wrap: wrap;
  }
  .formGroup {
    margin-top: 15px;
    min-width: auto;
    max-width: 350px;
  }
}
@media (max-width: 700px) {
  .group {
    display: block;
  }
  .formGroup {
    flex-wrap: wrap;
    width: 100%;
  }
  .control {
    margin-top: 15px;
  }
}