@import 'src/styles/vars';

.body:global(.modal-body) {
  padding: 45px 30px 15px;
  color: $text-color;
}

.footer:global(.modal-footer) {
  padding: 12px 30px;
}

.header {
  line-height: 26px;
  font-size: 24px;
  padding: 0 0 30px;
  color: inherit;
}

.options {
  line-height: 20px;
  font-size: 16px;
}

.radioGroup:global(.ant-radio-group) {
  display: flex;
  flex-direction: column;
}

.radioItem:global(.ant-radio-wrapper) {
  margin: 10px 10px 0 20px;
}
