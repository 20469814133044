@import 'src/styles/vars';

.menu {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  :global(.ant-collapse-borderless > .ant-collapse-item) {
    border-bottom: none !important;
  }
  :global(.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow) {
    display: none !important;
  }
}
.container {
  background-color: #fbfbfb !important;
  border-right: none !important;

}

.sub {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  text-decoration: none !important;
  color: $main !important;
  font-size: 16px;
  border-radius: 14px !important;

  width: 100%;
  transition: 0.4ms ease all;
  :global(.ant-collapse-header) {
    padding: 10px !important;
    display: flex;
  }
  :global(.ant-collapse-extra) {
    position: absolute;
    font-size: 25px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  :global(.ant-collapse-content) {
    background-color: #FBFBFB !important;
  }
  &:global(.active) {
    background-color: $green-yellow;
    transition: all .4s cubic-bezier(.08,.82,.17,1) 0s;
    box-shadow: 0 8px 14px rgba(62, 107, 224, 0.12);
  }
  &:hover:not(:global(.active)) {
    background-color: $green-yellow !important;
  }
}
.link {
  margin-bottom: 5px;
  text-decoration: none !important;
  color: $main !important;
  display: flex;
  align-items: center;
  font-size: 16px;
  border-radius: 14px;
  padding: 10px;
  width: 100%;
  transition: 0.4ms ease all;
  &:global(.active) {
    background-color: $green-yellow;
    transition: all .4s cubic-bezier(.08,.82,.17,1) 0s;
    box-shadow: 0 8px 14px rgba(62, 107, 224, 0.12);
  }
  &:hover:not(:global(.active)) {
    background-color: $green-yellow !important;
  }
}