@import 'src/styles/vars';


.sliderItem {
  padding-bottom: 230px !important;
  width: 230px;
  height: 225px;
  display: block;
  .cardItem {
    overflow: hidden;
    height: 225px !important;
    width: 240px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    transition: box-shadow 0.3s, border-color 0.3s;
    background: rgba(196, 196, 196, 0.28);
    :global(.ant-card-body) {
      padding: 15px;
      word-wrap: break-word;
    }
    :global(.anticon) {
      align-self: center;
    }
    .cardTitle {
      display: block;
      height: 55px;
      padding-top: 5px;
      overflow: hidden;
      max-width: 200px;
      word-wrap: break-word;
      font-weight: 500;
    }
    .image {
      width: 100%;
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(gray, 0.1);
      }
    }
  }
  strong {
    padding-top: 5px;
    color: #000;
    font-weight: 500;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    position: relative;
  }
}


.sliderView {
  display: flex;
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
}
.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  :global(.anticon svg) {
    width: 20px;
    height: 20px;
  }
  span {
    display: block;
  }
}
.cardIcon {
  display: flex;
}
.sliderContent {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  video {
    max-height: 400px;
  }
  figure, audio {
    width: 100%;
  }
  figure{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;
  }
  audio {
    color: $primary-color;
    margin-left: 50px;
    margin-right: 50px;
    &::-webkit-media-controls-panel {
      background-color: #D4D4D4;
    }
  }
}
.sliderInfo {
  width: 30%;
  background-color: #fff;
  padding: 15px;
  h2, h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: #6b7680;
    word-wrap: break-word;
  }
  p {
    color: #a1aeb7;
    font-size: 14px;
    line-height: 21px;

  }
}
.card {
  width: 240px;
  height: 180px;
  object-fit: cover;
}
.imgContent {
  display: block;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}
.description {
  font-size: 16px;
  color: #66696c;
}
@media (max-width: 500px) {
  .sliderView {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .sliderContent {
    width: 100%;
  }
  .sliderInfo {
    width: 100%;
  }
  .left, .right {
    bottom: 50%;
    transform: translateY(-50%);
  }
}
