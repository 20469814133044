.flex-row {
  position: relative;
  display: flex;
  flex-direction: row;
}

.flex-column {
  position: relative;
  display: flex;
  flex-direction: column;
}

.flex-center {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.leftColumn {
  composes: flex-column from global;
  flex: 1 1 auto;
  padding: 0 50px 50px 100px;
}

.rightColumn {
  composes: flex-column from global;
  flex: 0 0 340px;
}
