@import 'src/styles/vars';

.cell {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.date,
.time {
  padding: 0 10px;
}
