@import 'src/styles/vars';

.drawer {
  position: relative;
  height: 100%;
  width: 100%;

  :global(.ant-drawer-body) {
    background-color: $green-yellow;
  }
  :global(.ant-drawer-close) {
    color: $blue !important;
    &:hover {
      color: $dark-blue;
    }
  }
}

.body {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 160px 40px 40px;
  width: 100%;
  height: 100%;
  color: $main;
  background-color: $green-yellow;
}

.item {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.name {
  line-height: 34px;
  font-size: 26px;
  margin-top: 4px;
  font-weight: 500;
  white-space: nowrap;
}

.position {
  line-height: 20px;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 30px;
}

.title {
  line-height: 16px;
  font-size: 14px;
}

.info {
  line-height: 22px;
  font-size: 18px;
  margin-top: 4px;
  font-weight: 500;
}
.btn {
  background: $blue !important;
  color: #fff !important;
  transition: 0.3s ease all;
  &:hover {
    background: $dark-blue;
  }
}
.linkBtn {
  margin-top: 15px;
}
