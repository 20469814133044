@import 'src/styles/vars';

.socials {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.socialsContainer {
  position: relative;
  composes: flex-center from global;
  height: 30px;
  width: 30px;
  margin: 0 20px 10px 0;
  cursor: pointer;
}

.socialDisabled {
  cursor: unset;
}

.socialsIcon {
  position: relative;
  composes: flex-center from global;
  height: 100%;
  width: 100%;
}

.socialOther {
  composes: flex-center from global;
  height: 100%;
  width: 100%;

  & > div,
  & > span {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.icon {
  width: 30px;
  height: 30px;
  display: block;
  object-fit: cover;
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 15px;
}
