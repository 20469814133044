.form-control {
  height: $input-height;
  color: $main;
  background-color: $base-color;
  border-color: $border-color;
  outline: 0;
  border-radius: 6px;
  max-width: $max-input-width;

  &:focus {
    border-color: $border-color;
    box-shadow: 0 0 0 0.2rem rgba($border-color, 0.25);
  }

}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $main !important;
}
input {
  &:focus {
    outline-color: $accent-color !important;
  }
}

.nav-link {
  color: $accent-color !important;

  &:hover {
    color: $accent-color-dark !important;
  }
}
.ant-picker-content th, .ant-picker-today-btn {
  color: $blue !important;
}
.ant-form-item, .ant-radio-wrapper, .ant-picker-cell-in-view {
  color: $main !important;
}
input.ant-input,
span.ant-input-password,
div.ant-picker {
  max-height: 36px;
  border-radius: 6px;
  border: 1px solid #F5F5F5;
  color: $main !important;
  width: 100%;
  max-width: $max-input-width !important;
}
.ant-input-wrapper {
  .ant-btn {
    border-color: #F5F5F5 !important;
  }
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: $main !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $blue !important;
}
.ant-input-number {
  border-color: #A7A7AB !important;
}
.ant-input-number-input {
  color: $main !important;
}
.ant-input-number-handler {
  color: $main !important;
}
.ant-checkbox-wrapper {
  color: $main !important;
}
.ant-checkbox-inner {
  border-color: $main !important;
}
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: transparent;
  border-color: #ff4d4f;
}

.ant-btn {
  border-radius: 6px;
  border: 1px solid $blue !important;
  color: $main !important;
}

.ant-btn.ant-btn-sm {
  height: 28px;
}

.ant-btn-primary {
  color: $text-color-alt;
}

.ant-btn-circle {
  border-radius: 50%;
}

.ant-input-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  display: flex;
  align-items: center;
  min-height: 36px;
  min-width: 152px;
  border-radius: 6px;
  border: 1px solid #76767A;
  color: $main !important;
}

.ant-slider {
  &:hover .ant-slider-track {
    background-color: $blue !important;
  }

  &:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: $blue !important;
  }
}

.ant-slider-track {
  background-color: $blue !important;
}

.ant-slider-handle {
  border-color: $blue !important;
  &:hover {
    border-color: $blue !important;
  }
}

.ant-checkbox-wrapper {

  & .ant-checkbox-checked::after {
    border-color: $green-yellow !important;
  }

  &:hover .ant-checkbox-inner {
    border-color: $green-yellow !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $green-yellow !important;
    border-color: $green-yellow !important;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $green-yellow !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $green-yellow !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: $green-yellow !important;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}


.quill {
  color: unset;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: $blue !important;
}
.ant-select-dropdown {
  color: $main !important;
}
.ant-select-item {
  color: $main !important;
}
.ant-input-group {
  color: $main !important;
}

