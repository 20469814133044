@import "src/styles/vars";
.sidebar {
  background-color: #FBFBFB;
  transition: 0.3s ease-in-out;
  transition-property: width, margin-left, min-width;
  width: 300px;
  min-width: 300px;
  min-height: $height;
  padding: 20px 15px 140px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:global(.isMobile) {
    width: 100%;
    min-width: 100%;
  }
  &:global(:not(.isSidebarOpen):not(.isMobile)) {
    width: 80px;
    min-width: 80px;
    padding: 10px 15px 140px;
  }
  &:global(:not(.isSidebarOpen).isMobile) {
    margin-left: -100%;
  }

  &__content {
    position: sticky;
    top: 20px;
  }
  .link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    color: $main;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    text-decoration: none;
  }
  .text {
    font-size: 17px;
    line-height: 20px;
    color: $text-color-dark;
    display: block;
    @media (max-width: 400px) {
      display: none;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    width: 100%;
    .logo {
      display: block;
      width: 190px;
      height: 37px;
    }
    &:global(:not(.isSidebarOpen)) {
      svg {
        width: 100%;
      }
    }
    &:global(:not(.isSidebarOpen):not(.isMobile)) {
      flex-direction: column;
      :global(.title) {
        display: none;
      }
    }
  }

  &__cross {
    cursor: pointer;
    margin-top: 5px;
    margin-left: auto;
    display: none;
    @media (max-width: 992px) {
      display: block;
    }
  }
}

