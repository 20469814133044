@import 'src/styles/vars';

.body:global(.modal-body) {
  padding: 45px 30px 15px;
  color: $text-color;
}

.footer:global(.modal-footer) {
  padding: 12px 30px;
}

.header {
  line-height: 26px;
  font-size: 24px;
  padding: 0 0 30px;
  color: inherit;
}

.formItem {
  padding-bottom: 25px;
}

.label {
  line-height: 18px;
  font-size: 16px;
  color: $text-color;
}

.uploadedFilesList:global(.ant-form-item) {
  margin-top: 20px;
}

.existingFileLabel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
