@import 'src/styles/vars';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  padding: 50px;
}

.leftColumn,
.contentColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100%;
  padding-right: 100px;

  &:last-child {
    padding-right: 0;
  }
}

.leftColumn {
  width: 165px;
  max-width: 165px;
}

.contentColumn {
  flex: 1 1 50%;
  overflow: hidden;
  & :global(.ant-tabs) {
    margin-bottom: 40px;
  }

  & :global(.ant-tabs-tab.ant-tabs-tab-active) {
    border-bottom-color: $green;
    color: $main;

    & :global(.ant-tabs-tab-btn) {
      color: $main;
    }
  }

  & :global(.ant-tabs-ink-bar.ant-tabs-ink-bar-animated) {
    background-color: $blue;
  }

  & :global(.ant-tabs-top) {
    & :global(.ant-tabs-nav::before) {
      border-color: transparent;
    }
  }
}

.titleBlock {
  margin-bottom: 10px;
  color: $text-color-dark;
  :global(strong) {
    font-size: 18px;
    font-weight: 600;
  }
  :global(span) {
    font-size: 16px;
  }
}
.item {
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  composes: flex-column from global;
}

.multiFormat {
  margin-bottom: 0;
}

.title {
  line-height: 22px;
  font-size: 18px;
  font-weight: 600;
  color: $main;
  margin-bottom: 20px;
  min-width: 110px;
  span {
    margin-left: 15px;
    font-size: 18px;
    color: $text-color-dark;
  }
}
.btn {
  margin-bottom: 20px;
}
.text {
  line-height: 22px;
  font-size: 16px;
  color: $text-color-dark;
}

.video {
  max-width: 100%;
}

.row {
  position: relative;
  composes: flex-row from global;
  flex-wrap: wrap;
  margin-bottom: 26px;
  width: 100%;
}

.rowItem {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 50%;
  margin-right: 15px;
  margin-bottom: 10px;
  &:last-child {
    margin-right: 0;
  }
  & .title {
    margin-bottom: 4px;
  }

  & .text {
    line-height: 32px;
    font-size: 26px;
    color: $blue;
    overflow-wrap: break-word;
  }
}

.image {
  min-width: 100%;
  max-width: 100%;
  //max-height: 230px;
}

.name {
  line-height: 64px;
  font-size: 54px;
  font-weight: 600;
  color: $main;
}

.owner {
  font-weight: 400;
  color: $main;
  font-size: 18px;
}
.recommendedCostReduction {
  font-size: 18px;
  padding: 5px 10px;
  font-weight: 500;
  color: $main;
  background-color: $green-yellow;
}
.recommendedCostReductionSmall {
  font-size: 16px !important;
  span {
    white-space: nowrap;
  }
}
.budget {
  font-weight: 600;
}

.socials {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}


.documentsList {
  composes: flex-column from global;
}

.document {
  margin-bottom: 8px;
  cursor: pointer;
}

.pdfImg {
  height: 35px;
  width: 35px;
  margin-right: 28px;
}

.documentImg {
  height: 22px;
  width: 22px;
  margin-left: 28px;
}

.videoContent {
  color: #000;
}

.blogSphere {
  color: #000;
}
.implStatus {
  width: 100%;
  font-size: 18px;
  text-align: right;
  color: #000;
  &:global(.blue) {
    color: $blue;
  }
  &:global(.green) {
    color: $green;
  }
  &:global(&.red) {
    color: $coral;
  }
}
.multiFormat {
  color: #000;
}
@media (max-width: 990px) {
  .leftColumn,
  .contentColumn {
    min-height: 60px;
  }

  .container {
    flex-wrap: wrap;
  }
  .leftColumn {
    width: 100%;
    max-width: 100%;
  }
  .contentColumn {
    width: 100%;
    padding-right: 0;
    flex: 1 1 100%
  }
  .leftSection {
    order: 1;
  }
}
@media (max-width: 500px) {

  .container {
    padding: 15px 20px;
    overflow: hidden;
  }
  .text {
    overflow-wrap: break-word;
  }
  .name{
    font-size: 28px;
    line-height: 35px;
    overflow-wrap: break-word;
  }
  .owner {
    font-size: 24px;
    line-height: 28px;
  }
  .image {
    max-width: 100%;
    display: block;
  }
  .contentColumn {
    max-width: 500px;
    overflow: hidden;
    position: relative;
    & :global(.ant-tabs-tab + .ant-tabs-tab) {
      margin: 0 0 0 10px;
    }
  }
}
@media (max-width: 320px) {
  .contentColumn {
    & :global(.ant-tabs-nav-list) {
      flex-direction: column;
    }
    & :global(.ant-tabs-tab + .ant-tabs-tab) {
      margin: 0;
    }
  }
  .container {
    padding: 15px;
  }
}