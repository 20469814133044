@import 'src/styles/vars';

.body:global(.modal-body) {
  padding: 15px;
  color: $text-color;
}

.header {
  line-height: 26px;
  font-size: 24px;
  padding: 0;
  color: inherit;
}
.modalHeader {
  padding: 15px;
}
.body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  :global(.ant-form-item) {
    margin-bottom: 0;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}
.btn {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}