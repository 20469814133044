@import 'src/styles/vars';

.header {
  composes: flex-row from global;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.title {
  composes: flex-row from global;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  & > .iconContainer {
    width: 64px;
  }
}

.icon {
  fill: $blue;
  height: 44px;
  width: 62px;
}