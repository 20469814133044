@import 'src/styles/vars';

.cell {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.name {
  line-height: 22px;
  font-size: 16px;
}

.owner {
  line-height: 16px;
  font-size: 10px;
}
