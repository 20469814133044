@import 'src/styles/vars';

.body:global(.modal-body) {
  padding: 45px 30px 15px;
  color: $text-color;
}

.footer:global(.modal-footer) {
  padding: 12px 30px;
}

.header {
  line-height: 26px;
  font-size: 24px;
  padding: 0 0 30px;
  color: inherit;
}

.selectAll {
  padding: 0 0 5px;
}

.checkBox:global(.ant-checkbox-wrapper) {
  margin-right: 10px;
}

.item {
  padding: 6px 15px;
  cursor: pointer;
  border-bottom: 1px solid rgba($accent-color, .3);
}
