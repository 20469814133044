@import 'src/styles/vars';

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100%;
  width: 100%;
}

.imgCell {
  width: 50px;
  height: 50px;
}
.linkCell {}

.header {
  display: flex;
  align-items: center;
  button {
    margin-left: 10px;
  }
  margin-bottom: 20px;
}

.label {
  width: 100%;
}
.list {
  //display: flex !important;
  //flex-direction: column;
  //overflow-x: auto;
  label {
    width: 100%;
  }
}

.checkbox {
  margin-bottom: 20px;
  font-weight: bold;
}

.img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  margin-left: 5px;
  object-fit: contain;
}
