@import "src/styles/vars";
.ErrorAuth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  p {
    text-align: center;
    font-size: 14px;
  }
  &__btn {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
}