@mixin font($fname, $fstyle, $fweight, $furl) {
  @font-face {
    font-family: $fname;
    font-style: $fstyle;
    font-weight: $fweight;
    src: url($furl) format('woff2');
  }
}

@include font('Golos', normal, 400, '../assets/fonts/golos/Golos_Text_Regular.woff2');
@include font('Golos', normal, 500, '../assets/fonts/golos/Golos_Text_Medium.woff2');
@include font('Golos', normal, 600, '../assets/fonts/golos/Golos_Text_DemiBold.woff2');
@include font('Golos', normal, 700, '../assets/fonts/golos/Golos_Text_Bold.woff2');
@include font('Golos', normal, 900, '../assets/fonts/golos/Golos_Text_Black.woff2');