@import 'src/styles/vars';

.cell {
  position: relative;
  composes: flex-center from global;
  width: 100%;
}

.bullet {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $accent-color;
}

.active {
  background-color: $accent-green;
}

