@import "src/styles/vars";
.modal {
  width: 800px !important;
  @media (max-width: 800px) {
    width: 90%;
  }
}
.container {
  display: flex;
  flex-direction: column;
  color: $main;
  font-size: 16px;
}
.btn {
  display: flex;
  justify-content: flex-end;
}